@import '~antd/dist/antd.less';

@font-face {
  font-family: SFPro;
  src: url('../public/SF-Pro-Text-Regular.otf') format('opentype');
}

html,
body {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: SFPro, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: inherit;
}

@primary-color: #61175E;@processing-color: #61175E;@heading-color: #031849;@text-color: #031849;@text-color-secondary: #687492;@border-color-base: #CDD1DB;@tooltip-color: #031849;@tooltip-bg: #FBFBFF;@select-background: #FFFFFF;@select-item-selected-bg: #FAFAFA;@heading-1-size: 18px;@heading-2-size: 16px;@heading-3-size: 14px;@heading-4-size: 12px;@heading-5-size: 10px;